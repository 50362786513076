import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Text,
  useToast,
  VStack
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaCalculator, FaEye } from "react-icons/fa";
import * as yup from "yup";

import { Select } from "../components/Form/Select";

import api from "../services/api";

interface DegreesDataForm {
  product_id: string;
  spherical_right?: string;
  cylindrical_right?: string;
  axle_right?: string;
  plus_right?: string;
  spherical_left?: string;
  cylindrical_left?: string;
  axle_left?: string;
  plus_left?: string;
}

interface ResponseProduct {
  id: string;
  product_name: string;
  product_image: string;
  product_type: string;
}

interface Options {
  value: string | number; 
  label: string | number;
}

interface ResponseDegrees {
  sphericals: Options[];
  cylindricals: Options[];
}

interface ResponseResultDegreesRight {
  spherical_result: string;
  cylindrical_result?: string;
  axle_right?: string;
  plus_right?: string;
}

interface ResponseResultDegreesLeft {
  spherical_result: string;
  cylindrical_result?: string;
  axle_left?: string;
  plus_left?: string;
}

export const Home = () => {
  const [product, setProduct] = useState<ResponseProduct>();
  const [productType, setProductType] = useState("");
  const [products, setProducts] = useState<ResponseProduct[]>([]);
  const [selectOptionsProduct, setSelectOptionsProduct] = useState<Options[]>([]);
  const [selectOptionsPlus, setSelectOptionsPlus] = useState<Options[]>([]);
  const [selectOptionsSpherical, setSelectOptionsSpherical] = useState<Options[]>([]);
  const [selectOptionsCylindrical, setSelectOptionsCylindrical] = useState<Options[]>([]);
  const [selectOptionsAxleRight, setSelectOptionsAxleRight] = useState<Options[]>([]);
  const [selectOptionsAxleLeft, setSelectOptionsAxleLeft] = useState<Options[]>([]);
  const [resultDegreesRight, setResultDegreesRight] = useState<ResponseResultDegreesRight>();
  const [resultDegreesLeft, setResultDegreesLeft] = useState<ResponseResultDegreesLeft>();

  const createDegreesFormSchema = yup.object().shape({
    product_id: yup.string().required("Lente de Contato Obrigatório"),
    spherical_right: yup.string(),
    cylindrical_right: yup.string(),
    axle_right: yup.string(),
    plus_right: yup.string(),
    spherical_left: yup.string(),
    cylindrical_left: yup.string(),
    axle_left: yup.string(),
    plus_left: yup.string(),
  });

  const { register, handleSubmit, formState, setValue } = useForm<DegreesDataForm>({
    resolver: yupResolver(createDegreesFormSchema),
  });

  const { errors, isSubmitting } = formState;

  const toast = useToast();

  const handleDegrres = useCallback((product_id: string) => {
    const product = products.find(p => p.id === product_id);
    
    setProduct(product);

    if(product) {
      setValue("spherical_left", "");
      setValue("cylindrical_left", "");
      setValue("axle_left", "");
      setValue("plus_left", "");
      setValue("spherical_right", "");
      setValue("cylindrical_right", "");
      setValue("axle_right", "");
      setValue("plus_right", "");

      setProductType(product.product_type);

      api.get<ResponseDegrees>(`degrees/list/${product_id}`).then(response => {
        setSelectOptionsSpherical(response.data.sphericals);
        setSelectOptionsCylindrical(response.data.cylindricals);
        setProduct(product);
        setResultDegreesRight(undefined);
        setResultDegreesLeft(undefined);
      });
    }
  }, [products, setValue]);

  const handleAxleRight = useCallback((spherical: string, product_type: string) => {
    if(product_type === "LA") {
      if(parseFloat(spherical.replace("+", "").replace(",", ".")) <= parseFloat("0") && parseFloat(spherical.replace(",", ".")) >= parseFloat("-6,00")){
        setSelectOptionsAxleRight([
          {
            value: 10,
            label: 10
          },
          {
            value: 20,
            label: 20
          },
          {
            value: 30,
            label: 30
          },
          {
            value: 40,
            label: 40
          },
          {
            value: 50,
            label: 50
          },
          {
            value: 60,
            label: 60
          },
          {
            value: 70,
            label: 70
          },
          {
            value: 80,
            label: 80
          },
          {
            value: 90,
            label: 90
          },
          {
            value: 100,
            label: 100
          },
          {
            value: 110,
            label: 110
          },
          {
            value: 120,
            label: 120
          },
          {
            value: 130,
            label: 130
          },
          {
            value: 140,
            label: 140
          },
          {
            value: 150,
            label: 150
          },
          {
            value: 160,
            label: 160
          },
          {
            value: 170,
            label: 170
          },
          {
            value: 180,
            label: 180
          },
        ]);
      } else {
        setSelectOptionsAxleRight([
          {
            value: 10,
            label: 10
          },
          {
            value: 20,
            label: 20
          },
          {
            value: 70,
            label: 70
          },
          {
            value: 80,
            label: 80
          },
          {
            value: 90,
            label: 90
          },
          {
            value: 100,
            label: 100
          },
          {
            value: 110,
            label: 110
          },
          {
            value: 160,
            label: 160
          },
          {
            value: 170,
            label: 170
          },
          {
            value: 180,
            label: 180
          },
        ]);
      }
    }

    if(product_type === "LAP") {
      setSelectOptionsAxleRight([
        {
          value: 10,
          label: 10
        },
        {
          value: 20,
          label: 20
        },
        {
          value: 70,
          label: 70
        },
        {
          value: 80,
          label: 80
        },
        {
          value: 90,
          label: 90
        },
        {
          value: 100,
          label: 100
        },
        {
          value: 110,
          label: 110
        },
        {
          value: 160,
          label: 160
        },
        {
          value: 170,
          label: 170
        },
        {
          value: 180,
          label: 180
        },
      ]);
    }

    if(product_type === "LT") {
      setSelectOptionsAxleRight([
        {
          value: 10,
          label: 10
        },
        {
          value: 20,
          label: 20
        },
        {
          value: 70,
          label: 70
        },
        {
          value: 80,
          label: 80
        },
        {
          value: 90,
          label: 90
        },
        {
          value: 100,
          label: 100
        },
        {
          value: 110,
          label: 110
        },
        {
          value: 160,
          label: 160
        },
        {
          value: 170,
          label: 170
        },
        {
          value: 180,
          label: 180
        },
      ]);
    }

    if(product_type === "LM") {
      setSelectOptionsAxleRight([
        {
          value: 10,
          label: 10
        },
        {
          value: 20,
          label: 20
        },
        {
          value: 30,
          label: 30
        },
        {
          value: 40,
          label: 40
        },
        {
          value: 50,
          label: 50
        },
        {
          value: 60,
          label: 60
        },
        {
          value: 70,
          label: 70
        },
        {
          value: 80,
          label: 80
        },
        {
          value: 90,
          label: 90
        },
        {
          value: 100,
          label: 100
        },
        {
          value: 110,
          label: 110
        },
        {
          value: 120,
          label: 120
        },
        {
          value: 130,
          label: 130
        },
        {
          value: 140,
          label: 140
        },
        {
          value: 150,
          label: 150
        },
        {
          value: 160,
          label: 160
        },
        {
          value: 170,
          label: 170
        },
        {
          value: 180,
          label: 180
        },
      ]);
    }
  }, []);

  const handleAxleLeft = useCallback((spherical: string, product_type: string) => {
    if(product_type === "LA") {
      if(parseFloat(spherical.replace("+", "").replace(",", ".")) <= parseFloat("0") && parseFloat(spherical.replace(",", ".")) >= parseFloat("-6,00")){
        setSelectOptionsAxleLeft([
          {
            value: 10,
            label: 10
          },
          {
            value: 20,
            label: 20
          },
          {
            value: 30,
            label: 30
          },
          {
            value: 40,
            label: 40
          },
          {
            value: 50,
            label: 50
          },
          {
            value: 60,
            label: 60
          },
          {
            value: 70,
            label: 70
          },
          {
            value: 80,
            label: 80
          },
          {
            value: 90,
            label: 90
          },
          {
            value: 100,
            label: 100
          },
          {
            value: 110,
            label: 110
          },
          {
            value: 120,
            label: 120
          },
          {
            value: 130,
            label: 130
          },
          {
            value: 140,
            label: 140
          },
          {
            value: 150,
            label: 150
          },
          {
            value: 160,
            label: 160
          },
          {
            value: 170,
            label: 170
          },
          {
            value: 180,
            label: 180
          },
        ]);
      } else {
        setSelectOptionsAxleLeft([
          {
            value: 10,
            label: 10
          },
          {
            value: 20,
            label: 20
          },
          {
            value: 70,
            label: 70
          },
          {
            value: 80,
            label: 80
          },
          {
            value: 90,
            label: 90
          },
          {
            value: 100,
            label: 100
          },
          {
            value: 110,
            label: 110
          },
          {
            value: 160,
            label: 160
          },
          {
            value: 170,
            label: 170
          },
          {
            value: 180,
            label: 180
          },
        ]);
      }
    }

    if(product_type === "LAP") {
      setSelectOptionsAxleLeft([
        {
          value: 10,
          label: 10
        },
        {
          value: 20,
          label: 20
        },
        {
          value: 70,
          label: 70
        },
        {
          value: 80,
          label: 80
        },
        {
          value: 90,
          label: 90
        },
        {
          value: 100,
          label: 100
        },
        {
          value: 110,
          label: 110
        },
        {
          value: 160,
          label: 160
        },
        {
          value: 170,
          label: 170
        },
        {
          value: 180,
          label: 180
        },
      ]);
    }

    if(product_type === "LT") {
      setSelectOptionsAxleLeft([
        {
          value: 10,
          label: 10
        },
        {
          value: 20,
          label: 20
        },
        {
          value: 70,
          label: 70
        },
        {
          value: 80,
          label: 80
        },
        {
          value: 90,
          label: 90
        },
        {
          value: 100,
          label: 100
        },
        {
          value: 110,
          label: 110
        },
        {
          value: 160,
          label: 160
        },
        {
          value: 170,
          label: 170
        },
        {
          value: 180,
          label: 180
        },
      ])
    }

    if(product_type === "LM") {
      setSelectOptionsAxleLeft([
        {
          value: 10,
          label: 10
        },
        {
          value: 20,
          label: 20
        },
        {
          value: 30,
          label: 30
        },
        {
          value: 40,
          label: 40
        },
        {
          value: 50,
          label: 50
        },
        {
          value: 60,
          label: 60
        },
        {
          value: 70,
          label: 70
        },
        {
          value: 80,
          label: 80
        },
        {
          value: 90,
          label: 90
        },
        {
          value: 100,
          label: 100
        },
        {
          value: 110,
          label: 110
        },
        {
          value: 120,
          label: 120
        },
        {
          value: 130,
          label: 130
        },
        {
          value: 140,
          label: 140
        },
        {
          value: 150,
          label: 150
        },
        {
          value: 160,
          label: 160
        },
        {
          value: 170,
          label: 170
        },
        {
          value: 180,
          label: 180
        },
      ]);
    }
  }, []);

  const handleSubmitDegrees: SubmitHandler<DegreesDataForm> = async (data) => {
    setResultDegreesRight(undefined);
    setResultDegreesLeft(undefined);
    toast.closeAll();

    if(productType === "LA" || productType === "LAP" || productType === "LT") {
      if(
        (data.spherical_right && data.cylindrical_right && data.axle_right) || 
        (data.spherical_left && data.cylindrical_left && data.axle_left)
      ) {
        if (data.spherical_right && data.cylindrical_right) {
          api.post<ResponseResultDegreesRight>(`degrees/result/${data.product_id}`, {
            product_type: productType,
            spherical_degree: data.spherical_right,
            cylindrical_degree: data.cylindrical_right,
          }).then(response => {
            if(response.data) {
              setResultDegreesRight({
                ...response.data,
                axle_right: data.axle_right
              })
            } else {
              toast({
                title: "Olho Direito",
                description: `Não há conversão disponível com esférico ${data.spherical_right} e cilíndrico ${ data.cylindrical_right}.`,
                status: "warning",
                duration: null,
                isClosable: true,
              });
            }
          });
        }

        if (data.spherical_left && data.cylindrical_left) {
          api.post<ResponseResultDegreesLeft>(`degrees/result/${data.product_id}`, {
            product_type: productType,
            spherical_degree: data.spherical_left,
            cylindrical_degree: data.cylindrical_left,
          }).then(response => {
            if(response.data) {
              setResultDegreesLeft({
                ...response.data,
                axle_left: data.axle_left
              })
            } else {
              toast({
                title: "Olho Esquerdo",
                description: `Não há conversão disponível com esférico ${data.spherical_left} e cilíndrico ${ data.cylindrical_left}.`,
                status: "warning",
                duration: null,
                isClosable: true,
              });
            }
          });
        }
      } else {
        toast({
          title: "Campos esfera, cilindro e eixo são obrigatório",
          description: "Preenchar os campos esfera, cilindro e eixo do olho direito ou esquerdo",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }

    if(productType === "LM") {
      if(
        (data.spherical_right && !data.cylindrical_right) || 
        (data.spherical_right && data.cylindrical_right) || 
        (data.spherical_left && !data.cylindrical_left) || 
        (data.spherical_left && data.cylindrical_left)
      ) {
        if ((data.spherical_right && !data.cylindrical_right) || (data.spherical_right && data.cylindrical_right)) {
          let plus_right = data.plus_right;

          if(plus_right) {
            if(parseFloat(plus_right) <= 1.25) {
              plus_right = `${plus_right} LO`
            } else if (parseFloat(plus_right) >= 1.5 && parseFloat(plus_right) <= 2) {
              plus_right = `${plus_right} MED`
            } else if (parseFloat(plus_right) >= 2.25) {
              plus_right = `${plus_right} HI`
            } else {
              plus_right = `${plus_right}`
            }
          }
          
          api.post<ResponseResultDegreesRight>(`degrees/result/${data.product_id}`, {
            product_type: productType,
            spherical_degree: data.spherical_right,
            cylindrical_degree: data.cylindrical_right,
          }).then(response => {
            
            if(response.data.spherical_result) {
              if(parseFloat(response.data.spherical_result.replace(",", ".")) >= -10.00 && parseFloat(response.data.spherical_result.replace(",", ".").replace("+", "")) <= 6.00){
                setResultDegreesRight({
                  ...response.data,
                  axle_right: "",
                  plus_right,
                });
              } else {
                setResultDegreesRight({
                  ...response.data,
                  axle_right: "",
                  plus_right,
                });

                toast({
                  title: "Limite de multifocal olho direito",
                  description: "O parâmetro limite para grau positivo na multifocal vai até +6.00 e no grau negativo vai até -10.00, conforme guia",
                  status: "warning",
                  duration: null,
                  isClosable: true,
                });
              }
            }
          });
        } 

        if ((data.spherical_left && !data.cylindrical_left) || (data.spherical_left && data.cylindrical_left)) {
          let plus_left = data.plus_left;
          
          if(plus_left) {
            if(parseFloat(plus_left) <= 1.25) {
              plus_left = `${plus_left} LO`
            } else if (parseFloat(plus_left) >= 1.5 && parseFloat(plus_left) <= 2) {
              plus_left = `${plus_left} MED`
            } else if (parseFloat(plus_left) >= 2.25) {
              plus_left = `${plus_left} HI`
            } else {
              plus_left = `${plus_left}`
            }  
          }

          api.post<ResponseResultDegreesLeft>(`degrees/result/${data.product_id}`, {
            product_type: productType,
            spherical_degree: data.spherical_left,
            cylindrical_degree: data.cylindrical_left,
          }).then(response => {
            if(response.data.spherical_result) {
              if(parseFloat(response.data.spherical_result.replace(",", ".")) >= -10.00 && parseFloat(response.data.spherical_result.replace(",", ".").replace("+", "")) <= 6.00){ 
                setResultDegreesLeft({
                  ...response.data,
                  axle_left: "",
                  plus_left,
                });
              } else {
                setResultDegreesLeft({
                  ...response.data,
                  axle_left: "",
                  plus_left,
                });
                
                toast({
                  title: "Limite de multifocal olho esquerdo",
                  description: "O parâmetro limite para grau positivo na multifocal vai até +6.00 e no grau negativo vai até -10.00, conforme guia",
                  status: "warning",
                  duration: null,
                  isClosable: true,
                });
              }
            }
          });
        }
      } else {
        toast({
          title: "Campo Esfera Obrigatório",
          description: "Preenchar o campo esfera do olho direito ou esquerdo",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  }
  
  useEffect(() => {
    api.get<ResponseProduct[]>("products").then(response => {
      const arrayProducts = response.data.map(product => (
        {
          value: product.id,
          label: product.product_name,
        }
      ));
      setSelectOptionsProduct(arrayProducts);
      setProducts(response.data);
    });   
  }, []);

  useEffect(() => {
    setSelectOptionsPlus([
        {
          value: "+0.25",
          label: "+0,25"
        },
        {
          value: "+0.50",
          label: "+0,50"
        },
        {
          value: "+0.75",
          label: "+0,75"
        },
        {
          value: "+1.00",
          label: "+1,00"
        },
        {
          value: "+1.25",
          label: "+1,25"
        },
        {
          value: "+1.50",
          label: "+1,50"
        },
        {
          value: "+1.75",
          label: "+1,75"
        },
        {
          value: "+2.00",
          label: "+2,00"
        },
        {
          value: "+2.25",
          label: "+2,25"
        },
        {
          value: "+2.50",
          label: "+2,50"
        },
      ])   
  }, []);

  return (
    <Flex
      as="main"
      w="100%"
      my="16"
      justify="center"
      align="center"
    >
      <Flex
        w="100%"
        maxW={1280}
        justify="center"
        align="center"
        flexDirection="column"
      >
        <Heading
          as="h1"
          textTransform="uppercase"
          textAlign="center"
          color="brand.700"
          mb="8"
        >
          Calculadora de Lentes de Contato
        </Heading>

        <Box
          as="form"
          w="100%"
          maxW={900}
          px={["4", "auto"]}
          onSubmit={handleSubmit(handleSubmitDegrees)}
        >
          <Select
            label={"Lente de Contato"}
            placeholder={"...Selecione a lente de contato"}
            options={selectOptionsProduct}
            error={errors.product_id}
            {...register("product_id")}
            onChange={(e) => handleDegrres(e.currentTarget.value)}
          />

          {product && (
            <Flex w="100%" justify="center" align="center" my="12">
              <Flex
                w="100%"
                maxW={500}
                justify="center"
                align="center"
                flexDirection="column"
              >
                <Image
                  w="100%"
                  maxW={500}
                  boxShadow="dark-lg"
                  borderRadius="16"
                  src={process.env.NODE_ENV === "development" ? 
                  `${process.env.REACT_APP_URL_API_DEVELOPMENT}file/${product.product_image}` : 
                  `${process.env.REACT_APP_URL_API_PRODUCTION}file/${product.product_image}`}
                  alt={product.product_name} 
                />

                {product.product_type === "LM" && (
                  <Text
                    fontSize="md"
                    fontWeight="semibold"
                    color="orange.900"
                    textAlign="center"
                    mt="10"
                  >
                    A calculadora contempla o acréscimo de +0,25 no resultado da conversão, conforme o guia de adaptação.
                  </Text>
                )}
              </Flex>
            </Flex>
          )}

          <Heading
            as="h3"
            fontSize="2xl"
            textAlign="center"
            color="brand.700"
            my="8"
          >
            Introduza os valores da GRADUAÇÃO DOS ÓCULOS
          </Heading>

          
          <SimpleGrid flex="1" gap="8" minChildWidth="310px">
            <VStack spacing="4">
              <Flex justify="center" align="center">
                <Icon as={FaEye} fontSize="35" mr="2" color="brand.900" />
                <Text fontSize="2xl" fontWeight="bold" color="brand.900">
                  OLHO DIREITO
                </Text>
              </Flex>

              {product && (
                <>
                  <Select
                    label="Esfera:"
                    placeholder={"...Selecione a esfera"}
                    options={selectOptionsSpherical}
                    error={errors.spherical_right}
                    {...register("spherical_right")}
                    onChange={(e) => handleAxleRight(e.currentTarget.value, product.product_type)}
                  />
                  
                  <Select
                    label="Cilindro:"
                    placeholder={"...Selecione o cilindro"}
                    options={selectOptionsCylindrical}
                    error={errors.cylindrical_right}
                    {...register("cylindrical_right")}
                  />
                  
                  <Select
                    label={"Eixo:"}
                    placeholder={"...Selecione o eixo"}
                    options={selectOptionsAxleRight}
                    error={errors.axle_right}
                    {...register("axle_right")}
                  />
                  
                  {product.product_type === "LM" && (
                    <Select
                      label={"Adição:"}
                      placeholder={"...Selecione a adição"}
                      options={selectOptionsPlus}
                      error={errors.plus_right}
                      {...register("plus_right")}
                    />
                  )}
                </>
              )}
            </VStack>

            <VStack spacing="4">
              <Flex justify="center" align="center">
                <Icon as={FaEye} fontSize="35" mr="2" color="brand.900" />
                <Text fontSize="2xl" fontWeight="bold" color="brand.900">
                  OLHO ESQUERDO                  
                </Text>
              </Flex>
              
              {product && (
                <>
                  <Select
                    label="Esfera:"
                    placeholder={"...Selecione a esfera"}
                    options={selectOptionsSpherical}
                    error={errors.spherical_left}
                    {...register("spherical_left")}
                    onChange={(e) => handleAxleLeft(e.currentTarget.value, product.product_type)}
                  />

                  <Select
                    label="Cilindro:"
                    placeholder={"...Selecione o cilindro"}
                    options={selectOptionsCylindrical}
                    error={errors.cylindrical_left}
                    {...register("cylindrical_left")}
                  />

                  <Select
                    label={"Eixo:"}
                    placeholder={"...Selecione o eixo"}
                    options={selectOptionsAxleLeft}
                    error={errors.axle_left}
                    {...register("axle_left")}
                  />

                  {product.product_type === "LM" && (
                    <Select
                      label={"Adição:"}
                      placeholder={"...Selecione a adição"}
                      options={selectOptionsPlus}
                      error={errors.plus_left}
                      {...register("plus_left")}
                    />
                  )}
                </>
              )}
            </VStack>
          </SimpleGrid>

          <Flex w="100%" justify="center" align="center" my="6">
            <Button
              type="submit"
              bgColor="brand.900"
              color="white"
              fontSize="4xl"
              fontWeight="bold"
              borderRadius="16"
              w={["100%", "100%", "100%", "700px"]}
              h="65px"
              _hover={{
                filter: "brightness(0.8)",
              }}
              isLoading={isSubmitting}
            >
              <Icon as={FaCalculator} fontSize="28" mr="3" />
              Calcular
            </Button>
          </Flex>

          <Flex w="100%" justify="center" align="center" my="12">
            <Flex
              w="100%"
              maxW="750px"
              h={["auto", "250px"]}
              justify="center"
              align="center"
              border="3px solid transparent"
              borderColor="brand.900"
              borderRadius="10"
              p="10"
              boxShadow="dark-lg"
            >
              {(!resultDegreesLeft && !resultDegreesRight) && (
                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  color="orange.900"
                  textTransform="uppercase"
                  textAlign="center"
                >
                  Veja aqui o resultado!
                </Text>
              )}

              {(resultDegreesLeft || resultDegreesRight) && (
                <SimpleGrid
                  flex="1"
                  gap="8"
                  minChildWidth="290px"
                >
                  {resultDegreesRight && (
                    <VStack spacing="1">
                      <Flex justify="center" align="center">
                        <Icon as={FaEye} fontSize="35" mr="3" color="orange.900" />
                        <Text
                          fontSize="2xl"
                          fontWeight="bold"
                          textTransform="uppercase"
                          color="orange.900"
                        >
                          OLHO DIREITO
                        </Text>
                      </Flex>

                      <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        color="orange.900"
                        textTransform="uppercase"
                        textAlign="center"
                      >
                        {resultDegreesRight.spherical_result && `Esfera: ${resultDegreesRight.spherical_result}`}
                      </Text>
                      <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        color="orange.900"
                        textTransform="uppercase"
                        textAlign="center"
                      >
                        {resultDegreesRight.cylindrical_result && `Cilindro: ${resultDegreesRight.cylindrical_result}`}
                      </Text>
                      <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        color="orange.900"
                        textTransform="uppercase"
                        textAlign="center"
                      >
                        {resultDegreesRight.axle_right && `${"Eixo:"} ${resultDegreesRight.axle_right}`}
                      </Text>
                      <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        color="orange.900"
                        textTransform="uppercase"
                        textAlign="center"
                      >
                        {resultDegreesRight.plus_right && `${"Adição:"} ${resultDegreesRight.plus_right}`}
                      </Text>
                    </VStack>
                  )}

                  {resultDegreesLeft && (
                    <VStack spacing="1">
                      <Flex justify="center" align="center">
                        <Icon as={FaEye} fontSize="35" mr="3" color="orange.900" />
                        <Text
                          fontSize="2xl"
                          fontWeight="bold"
                          textTransform="uppercase"
                          color="orange.900"
                        >
                          OLHO ESQUERDO
                        </Text>
                      </Flex>

                      <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        color="orange.900"
                        textTransform="uppercase"
                        textAlign="center"
                      >
                        {resultDegreesLeft.spherical_result && `Esfera: ${resultDegreesLeft.spherical_result}`}
                      </Text>
                      <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        color="orange.900"
                        textTransform="uppercase"
                        textAlign="center"
                      >
                        {resultDegreesLeft.cylindrical_result && `Cilindro: ${resultDegreesLeft.cylindrical_result}`}
                      </Text>
                      <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        color="orange.900"
                        textTransform="uppercase"
                        textAlign="center"
                      >
                        {resultDegreesLeft.axle_left && `${"Eixo:"} ${resultDegreesLeft.axle_left}`}
                      </Text>
                      <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        color="orange.900"
                        textTransform="uppercase"
                        textAlign="center"
                      >
                        {resultDegreesLeft.plus_left && `${"Adição:"} ${resultDegreesLeft.plus_left}`}
                      </Text>
                    </VStack>
                  )}
                </SimpleGrid>
              )}
            </Flex>
          </Flex>
            
          <Flex w="100%" justify="center" align="center">
            <Text
              fontSize="lg"
              fontWeight="semibold"
              color="orange.900"
              textAlign="center"
            >
              As informações geradas nesse site são sugestões exclusivas para lentes de contato da Alcon e deverão ser validadas por profissional de saúde qualificado. Faça o acompanhamento regular com um especialista.
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}